.app {
  padding: 1.5rem;
  display: grid;
}

.bg-dark {
  background-color: $backgroundColorTransparent;
  border-radius: 5px;
  padding: 1rem;
}

.menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-container {
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

.navbar-container .bg-dark {
  padding: 1rem;
}

.panels-container {
  display: grid;
  grid-gap: 1rem;

  margin-top: 1rem;
}

.container-open {
  background-color: $backgroundColorDark;
  padding: 3rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.1);
}

#timeframes {
  background-color: rgba(0, 0, 0, 0.5); /* set the background color to partly transparent black */
  color: white; /* set the text color to white */
  border: none; /* remove the border */
  padding: 5px; /* add some padding for better spacing */
  border-radius: 5px; /* round the corners */
  cursor: pointer; /* change the cursor to a pointer when hovering over the element */
  padding: .5rem;
  width: 100%;

  option {
    background-color: rgba(0, 0, 0, 0.8); /* set the background color of each option to more opaque black */
  }
}


.desktop-menu {
  display: none;
  width: 100%;
  
  @media screen and (min-width: 700px) {
    display: flex;
  }
 
}

.mobile-menu {
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 700px) {
    display: none;
  }
}


/* Panels Container Columns */
@media screen and (min-width: 1000px) {
  .panels-container {
    grid-template-columns: 4fr 3fr 3fr;
  }
}

/* Navbar Columns */
@media screen and (min-width: 900px) {
  .navbar-container {
    grid-template-columns: 5fr 1fr;
  }
}

@media screen and (min-width: 1200px) {
  .navbar-container {
    grid-template-columns: 6fr 1fr;
  }
}

@media screen and (min-width: 1400px) {
  .navbar-container {
    grid-template-columns: 7fr 1fr;
  }
}

@media screen and (min-width: 1600px) {
  .navbar-container {
    grid-template-columns: 8fr 1fr;
  }
}

