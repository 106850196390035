.custom-select {
    position: relative;
    display: inline-block;
     
    background-color: $backgroundColorTransparent; /* set the background color to partly transparent black */
    color: white; /* set the text color to white */
    border: none; /* remove the border */
    padding: 5px; /* add some padding for better spacing */
    border-radius: 5px; /* round the corners */
    cursor: pointer; /* change the cursor to a pointer when hovering over the element */
    padding: .5rem;
  }

  .custom-select-label-text {
    display: none;
    font-size: 1rem;
  }
  
  .custom-select-label.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .custom-select-options {
    position: absolute;
   
    top: 105%;
    border-radius: 5px;
    border-top: 1px solid #ccc;
    left: -50%;
    width: 200%;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: $backgroundColorDark;
    border: 1px solid #ccc;
    
    
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.2s ease-in-out;
    z-index: 1;
  }

  .custom-select-options.open {
    transform: scaleY(1);
    }
    
    .custom-select-option {
      padding: 10px;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;
    }
    
    .custom-select-option:hover {
      background-color: $accent-green;
    }
    
    .custom-select-option.selected {
      background-color: $accent-green;
    }
   



@media (min-width: 660px) {
  .custom-select {
    width: 100%;
  }

    .custom-select-label-icon {
      display: none;
    }

    .custom-select-label-text {
      display: flex;
      justify-content: space-between;
    }

    .custom-select-options {
      top: 100%;
      left: 0;
      width: 100%;
      border-top: none;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }