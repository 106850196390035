.cards-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;

    @media screen and (min-width: $breakpoint-tablet) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
    }
        


    .card-component {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &__heading {
            text-align: center;

            h1 {
                color: white;
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }
        

        &__description {
            text-align: center;

            p {
                font-size: 1rem;
                line-height: 1.5rem;

              
            }

            .negative {
                color: $negative;
            }

            .positive {
                color: $positive;
            }
        }

        
    }
}


