/* ========= Colors ===== */
$backgroundColorTransparent:  rgba(23, 24, 22, 0.6);
$backgroundColorDark:  rgba(23, 24, 22, 1);
$backgroundColor2: #63696b;
$accent-green: rgba(78,173,152,1);
$accent-yellow: rgba(240,182,77,1);
$positive: #00ff00;
$negative: #ff0000;


// Model specific colors
$ORGANIC: #a2a3a5;
$FIBANN: #33ccff;
$HESOYAM_II: #8080ff;
$HESOYAM_II_S: #00e6b8;
$HESOYAM_II_H: #c5b785;
$HESOYAM_II_PLUS: #d7e4de;
$HESOYAM_II_BOCANNII: #535A6C;

/* ========= Breakpoints ===== */
$breakpoint-mobile: 480px;
$breakpoint-tablet: 680px;
$breakpoint-desktop: 1024px;
$breakpoint-desktop-large: 1200px;