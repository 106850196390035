.feed-section {
    overflow: hidden;

    .coin-marquee-header-signature,
    .coin-marquee-header {
        @media screen and (max-width: 768px) {
            // display: none;
            width: 120px;
        }
    }

    .coin-marquee-wrapper {
        @media screen and (max-width: 768px) {
            width: 500px;
        }
        
    }
}